.hired-dev-info {
    margin: 12px;
    padding: 12px 16px;
    color: #fff;
    background-color: #888;
    border: 1px solid gray;
    border-radius: 4px;
    box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.4);
}
.hired-dev-card {
    margin: 12px;
    padding: 0 16px;
    color: #fff;
    background-color: #777;
    border: 1px solid gray;
    border-radius: 4px;
    box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.4);
}
