.header {
    background-color: #333;
    color: #fff;
    padding: 40px 20px;
}
.title {
    text-align: center;
    font-size: 48px;
    text-shadow: 4px 4px 20px blueviolet;
}
.description {
    text-align: center;
}
.market-value {
    text-align: center;
    font-size: 24px;
    color: lightblue;
}