.dev-card {
    text-align: center;
    margin: 12px;
    padding: 20px 8px;
    background-color: whitesmoke;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    transition: 0.3s;
}
.dev-card:hover {
    background-color: #fff;
    box-shadow: 0 0 16px 0 rgba(119, 45, 45, 0.2);
}
.dev-img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
}
.btn {
    font-size: 16px;
    padding: 6px 42px;
    border: 1px solid gray;
    border-radius: 3px;
    cursor: pointer;
}
.btn-hire {
    color: #fff;
    background-color: #4caf50;
    border: 2px solid #4caf4c;
}
.btn-hire:hover {
    background-color: #555;
}